import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';

import { signUpEvents } from '@lib/events/signUp.event';

import { MyKPMessageThreadRoute, RegisterRoute } from 'server/routes';

import { AdAPI } from '@store/ad/api';
import { fetchAd } from '@store/ad/thunk';
import { AdInfo } from '@store/ad/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { ClickOnPhoneButtonPayload } from '@store/logging/type';
import { UserAPI } from '@store/user/api';

import {
  OAuthRegisterPayload,
  OpenMessageThreadPayload,
  RegisterPayload,
  UserSummaryPayload,
} from './type';
import { onErrorRegister, onSuccessRegister } from './utils';

export const fetchUserSummary = createAppAsyncThunk(
  'user/fetchUserSummary',
  async (payload: UserSummaryPayload, thunkAPI) => {
    const { data } = await UserAPI.fetchUserSummary(thunkAPI)(payload);

    return data.results;
  }
);

export const fetchUserAdsSummary = createAppAsyncThunk(
  'user/fetchUserAdsSummary',
  async (payload: UserSummaryPayload, thunkAPI) => {
    const { data } = await AdAPI.fetchUserAdsSummary(thunkAPI)(payload);

    return data.results;
  }
);

export const register = createAppAsyncThunk(
  'user/register',
  async (payload: RegisterPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const socialNetwork = 'direct';
    signUpEvents.clickRegister(socialNetwork);
    try {
      const { data } = await UserAPI.register(thunkAPI)(payload);

      if (data.success) {
        signUpEvents.successRegister(socialNetwork);
        Router.push(RegisterRoute.generateUrl({}, { page: 1 }));
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const registerViaOAuth = createAppAsyncThunk(
  'user/registerViaOAuth',
  async (payload: OAuthRegisterPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const { socialNetwork } = payload;
    signUpEvents.clickRegister(socialNetwork);
    try {
      const { signInWithPopup, auth, getProvider } = await import(
        '../../main/firebase'
      );
      const provider = getProvider(socialNetwork);
      const result = await signInWithPopup(auth, provider);
      const accessToken = await result.user.getIdToken();

      const { data } = await UserAPI.registerViaOAuth(thunkAPI)({
        accessToken,
        socialNetwork,
      });

      if (data.success) {
        onSuccessRegister(data, socialNetwork, dispatch);
      }
      return data;
    } catch (e) {
      const { errors } = e.response.data;
      onErrorRegister(e);
      return rejectWithValue(errors);
    }
  }
);

export const activateAccountViaOAuth = createAppAsyncThunk(
  'user/activateAccountViaOAuth',
  async (socialNetwork: string, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { data } = await UserAPI.registerViaOAuth(thunkAPI)({
      accessToken: '',
      socialNetwork: '',
    });

    if (data.success) {
      onSuccessRegister(data, socialNetwork, dispatch);
    }

    return data.results;
  }
);

export const openMessageThread = createAppAsyncThunk(
  'user/openMessageThread',
  async (payload: OpenMessageThreadPayload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { id, userId } = payload;

    if (getState().auth.token) {
      Router.push(
        MyKPMessageThreadRoute.generateUrl({}, { adId: id, user2Id: userId })
      );
    }
  }
);

export const clickOnPhoneButton = createAppAsyncThunk(
  'user/clickOnPhoneButton',
  async (payload: ClickOnPhoneButtonPayload, { getState, dispatch }) => {
    const { onSuccess, adId } = payload;
    const { token } = getState().auth;
    const { user } = getState().ad.byId?.[adId] ?? {};
    const { phone, hasPhone } = user ?? {};

    if (token && !isEmpty(phone)) {
      onSuccess(phone);
    }

    const userIsLoggedInHasPhoneButEmpty = token && hasPhone && isEmpty(phone);

    if (userIsLoggedInHasPhoneButEmpty) {
      dispatch(fetchAd({ adId })).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          const { phone: phoneAfterLogin } =
            (data.payload as AdInfo).user ?? {};
          onSuccess(phoneAfterLogin);
        }
      });
    }
  }
);
