/* eslint-disable import/no-cycle */
/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import Router from 'next/router';

import { loginEvents } from '@lib/events/login.event';

import { clearHeader } from '@api/request/clearHeader';

import { AccountActivationRoute, LoginRoute } from '@server/routes';

import { AuthAPI } from '@store/auth/api';
import { resetAccessControlInfo } from '@store/generalStats/slice';
import { Any, Error } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { MeAccountAPI } from '@store/meAccount/api';
import { fetchUserProfile } from '@store/meProfile/thunk';
import { resetMeStats } from '@store/meStats/slice';
import { resetSearchState } from '@store/search/slice';

import {
  ChangePasswordResponse,
  CheckVerificationCodePayload,
  LoginPayload,
  LoginViaOAuthPayload,
  LogoutPayload,
  ResetPasswordResponse,
  SendVerificationCodePayload,
} from './type';
import {
  onFailureCheckVerificationCode,
  onFailureLogin,
  onSuccessLogin,
  setLoading,
} from './utils';

const authErrorCode = ['user_email_exist', 'user_login_error'];

export const fetchToken = createAppAsyncThunk(
  'auth/fetchToken',
  async (_, thunkAPI) => {
    const { data } = await AuthAPI.getToken(thunkAPI)();

    return data.token;
  }
);

export const setToken = createAppAsyncThunk(
  'auth/setToken',
  async (payload: string) => {
    return payload;
  }
);

export const saveLoginPayload = createAppAsyncThunk(
  'auth/saveLoginPayload',
  async (payload: LoginPayload) => {
    return payload;
  }
);

export const login = createAppAsyncThunk(
  'auth/login',
  async (payload: LoginPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const socialNetwork = 'direct';
    loginEvents.clickLogin(socialNetwork);
    await dispatch(saveLoginPayload(payload));
    try {
      const { data } = await AuthAPI.login(thunkAPI)(payload);
      await onSuccessLogin(dispatch, socialNetwork, data, setToken);
      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        errors.map((error) => {
          if (authErrorCode.includes(error.error_code)) {
            loginEvents.failLogin(socialNetwork);
          }
        });

        await onFailureLogin(errors, payload, dispatch);
        return rejectWithValue(errors);
      }
    }
  }
);

export const loginViaOAuth = createAppAsyncThunk(
  'auth/loginViaOAuth',
  async (payload: LoginViaOAuthPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const { socialNetwork } = payload;
    loginEvents.clickLogin(socialNetwork);

    try {
      const { signInWithPopup, auth, getProvider } = await import(
        '../../main/firebase'
      );
      const provider = getProvider(socialNetwork);
      const result = await signInWithPopup(auth, provider);
      const accessToken = await result.user.getIdToken();
      dispatch(setLoading(socialNetwork));
      const { data } = await AuthAPI.loginViaOAuth(thunkAPI)({
        accessToken,
        socialNetwork,
      });

      if (data.activated) {
        loginEvents.successLoginActivation(socialNetwork);
      }
      await onSuccessLogin(dispatch, socialNetwork, data, setToken);

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        errors.map((error: Error) => {
          if (authErrorCode.includes(error.error_code)) {
            loginEvents.failLogin(socialNetwork);
            Router.push(
              AccountActivationRoute.generateUrl({}, { status: socialNetwork })
            );
          }
        });

        await onFailureLogin(errors, payload, dispatch);
        return rejectWithValue(errors);
      }
    }
  }
);

export const logout = createAppAsyncThunk(
  'auth/logout',
  async (payload: LogoutPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    await AuthAPI.logout(thunkAPI)();
    const { signOut, auth } = await import('../../main/firebase');
    await signOut(auth);

    clearHeader('Authorization');
    dispatch(resetMeStats());
    dispatch(resetAccessControlInfo());
    dispatch(resetSearchState());

    try {
      window.localStorage.setItem('logout', Date.now().toString());
      window.localStorage.removeItem('meStats/groupedInfo');
      window.localStorage.removeItem('loginViaOAuth');
      document.body.dataset.theme = 'light';
      document.documentElement.setAttribute('data-theme', 'light');
    } catch (e) {}

    Router.push(LoginRoute.generateUrl());

    return payload;
  }
);

export const autoLogin = createAppAsyncThunk(
  'auth/autoLogin',
  async (payload: Any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { isLoading } = getState().auth;
    if (isLoading) {
      return '';
    }
    if (payload) {
      let socialNetwork = '';
      payload.providerData.forEach((profile) => {
        socialNetwork = profile.providerId.substr(
          0,
          profile.providerId.indexOf('.')
        );
      });
      const accessToken = await payload.getIdToken();

      const { data } = await AuthAPI.loginViaOAuth(thunkAPI)({
        accessToken,
        socialNetwork,
      });

      await onSuccessLogin(dispatch, socialNetwork, data, setToken);

      return data.token;
    }
  }
);

export const changePassword = createAppAsyncThunk(
  'auth/changePassword',
  async (payload: ChangePasswordResponse, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await AuthAPI.changePassword(thunkAPI)(payload);

      if (data.success) {
        dispatch(logout({ changePassword: true }));
      }

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        return rejectWithValue(errors);
      }
    }
  }
);

export const createPassword = createAppAsyncThunk(
  'auth/createPassword',
  async (payload: Any, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const { data } = await MeAccountAPI.createPassword(thunkAPI)(payload);

      if (data.success) {
        dispatch(logout({ createPassword: true }));
      }

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;

        return rejectWithValue(errors);
      }
    }
  }
);

export const changeEmailRequest = createAppAsyncThunk(
  'auth/changeEmailRequest',
  async (payload: Any, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const { data } = await MeAccountAPI.changeEmailRequest(thunkAPI)(payload);

      if (data.success) {
        dispatch(fetchUserProfile());
      }

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;

        return rejectWithValue(errors);
      }
    }
  }
);

export const resetPassword = createAppAsyncThunk(
  'auth/resetPassword',
  async (payload: ResetPasswordResponse, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await AuthAPI.resetPassword(thunkAPI)(payload);

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;

        return rejectWithValue(errors);
      }
    }
  }
);

export const sendVerificationCode = createAppAsyncThunk(
  'auth/sendVerificationCode',
  async (payload: SendVerificationCodePayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await AuthAPI.sendVerificationCode(thunkAPI)(payload);
      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        if (payload.initSend) return;
        const { errors = '' } = e.response.data;
        return rejectWithValue(errors);
      }
    }
  }
);

export const checkVerificationCode = createAppAsyncThunk(
  'auth/checkVerificationCode',
  async (payload: CheckVerificationCodePayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await AuthAPI.checkVerificationCode(thunkAPI)(payload);
      await onSuccessLogin(dispatch, 'direct', data, setToken);
      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        await onFailureCheckVerificationCode(errors);
        return rejectWithValue(errors);
      }
    }
  }
);

export const getVerificationInfo = createAppAsyncThunk(
  'auth/getVerificationInfo',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await AuthAPI.verificationInfo(thunkAPI)();
      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        return rejectWithValue(errors);
      }
    }
  }
);

export const loginViaOneTap = createAppAsyncThunk(
  'auth/loginViaOneTap',
  async (credential: string, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const socialNetwork = 'google';

    try {
      const { handleCredential } = await import('../../main/firebase');
      const result = await handleCredential(credential);
      const accessToken = await result.user.getIdToken();

      const { data } = await AuthAPI.loginViaOAuth(thunkAPI)({
        accessToken,
        socialNetwork,
      });

      await onSuccessLogin(dispatch, socialNetwork, data, setToken);

      return data;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        errors.map((error: Error) => {
          if (authErrorCode.includes(error.error_code)) {
            loginEvents.failLogin(socialNetwork);
            Router.push(
              AccountActivationRoute.generateUrl({}, { status: socialNetwork })
            );
          }
        });

        await onFailureLogin(errors, {}, dispatch);
        return rejectWithValue(errors);
      }
    }
  }
);
