/* eslint-disable prefer-destructuring */
const { Route } = require('@lib/router/Route');
const urlify = require('./lib/urlify');

let API_URL = process.env.API_URL;

if (!process.env.ENVIRONMENT || !process.env.API_URL) {
  // eslint-disable-next-line global-require
  const { publicRuntimeConfig } = require('next/config').default();
  API_URL = publicRuntimeConfig.API_URL;
}

const HomeRoute = new Route('/');
const HomeServiceRoute = new Route('/usluge');
const HomeJobRoute = new Route('/poslovi');
const HomeShopWindowRoute = new Route('/kpizlozi');
const HomeFeedbackFormOpenRoute = new Route('/predlog-problem-kontakt');

const CategoryShopWindowRoute = new Route('/kpizlozi/:categoryId');
const CategoryRoute = new Route('/:firstParam/kategorija/:categoryId', urlify);
const CategoryServiceRoute = new Route(
  '/:firstParam/kategorija/:categoryId/usluge',
  urlify
);
const GroupRoute = new Route(
  '/:firstParam/:group/grupa/:categoryId/:groupId/:page',
  urlify
);
const SearchRoute = new Route('/pretraga', urlify);
const CategorySearchRoute = new Route('/:firstParam/pretraga', urlify);
const CategoryNewestRoute = new Route(
  '/:firstParam/kategorija/:categoryId/najnoviji',
  urlify
);
const GroupSearchRoute = new Route('/:firstParam/:group/pretraga', urlify);
const NewestSearchRoute = new Route('/najnoviji/:page');
const SavedSearchRoute = new Route('/sacuvana-pretraga/:hash');
const SavedSearchNotificationRoute = new Route(
  '/sacuvana-pretraga-obavestenje/:notificationId/:hash'
);
const AllUserAdsRoute = new Route(
  '/:firstParam/svi-oglasi/:userId/:page',
  urlify
);

const AdRoute = new Route('/:firstParam/:group/:ad/oglas/:adId', urlify);
const AboutUsRoute = new Route('/o-nama');

const AllUserReviewsRoute = new Route(
  '/:firstParam/ocene/:userId/:page',
  urlify
);

const HelpAndContactRoute = new Route('/pomoc-kontakt');
const PrivacyPolicyRoute = new Route('/polisa-o-postovanju-privatnosti');
const PrivacyPolicy19072018Route = new Route(
  '/polisa-o-postovanju-privatnosti-19-07-2018'
);
const PrivacyPolicy18052020Route = new Route(
  '/polisa-o-postovanju-privatnosti-18-05-2020'
);
const TermsAndConditionsRoute = new Route('/pravila-uslovi');
const Terms20122022Route = new Route('/pravila-uslovi-20-12-2022');
const Terms09062022Route = new Route('/pravila-uslovi-09-06-2022');
const Terms05122016Route = new Route('/pravila-uslovi-05-12-2016');
const Terms14032019Route = new Route('/pravila-uslovi-14-03-2019');
const Terms05012022Route = new Route('/pravila-uslovi-05-01-2022');
const Terms29092021Route = new Route('/pravila-uslovi-29-09-2021');
const FairUsePolicyRoute = new Route('/polisa-o-fer-koriscenju');
const PrepaidTermsRoute = new Route('/prepaid-pravila-uslovi');
const SellerRightsRoute = new Route('/pravaiobavezeprodavaca');
const CourierTermsAndConditionsRoute = new Route(
  '/zakazivanje-kurira-pravila-uslovi'
);

const SmallBusinessRoute = new Route('/malaprivreda');
const KPLinkedInRoute = new Route(
  'https://www.linkedin.com/company/kupujemprodajem-com'
);
const KPFacebookRoute = new Route('https://www.facebook.com/KupujemProdajem');
const KPInstagramRoute = new Route(
  'https://www.instagram.com/kupujemprodajem/'
);
const KPTikTokRoute = new Route('https://www.tiktok.com/@kupujemprodajem');
const KPPlayStoreRoute = new Route(
  'https://play.google.com/store/apps/details?id=com.kupujemprodajem.android'
);
const KPAppleStoreRoute = new Route(
  'https://apps.apple.com/app/apple-store/id1400660034?pt=119041794&ct=kp&mt=8'
);
const KPBookBearRoute = new Route(
  'https://kpknjige.com/product/medvedi-na-putu-bojan-lekovic/?utm_source=kp&utm_medium=footer&utm_campaign=kupujemprodajem'
);
const KPCareersRoute = new Route(
  'https://blog.kupujemprodajem.com/kp-karijere-otvorene-pozicije-u-kupujemprodajem/?utm_source=kp&utm_medium=footer&utm_campaign=kp_karijere'
);
const KPBookRoute = new Route('https://kpknjige.com');

const AdPromoRoute = new Route('/promocije-oglasa');
const MediakitRoute = new Route('/mediakit');
const PriceListRoute = new Route('/cenovnik');
const BannerInstructionRoute = new Route('/uputstvo-za-banere');
const PrepaidRoute = new Route('/dopuna-kredita');
const AdPromoSpecificRoute = new Route('/promocije-oglasa/:type');

const KpIzlogRoute = new Route('https://kpizlog.rs');
const OtpLandingPageRoute = new Route(
  'https://www.otpbanka.rs/kupujem-prodajem/?utm_source=kupujemprodajem.com&utm_medium=ros&utm_campaign=OTP_kupujemprodajem&utm_content=baner&crmc=a3ec860f-c7e9-fb7a-7308-6124008c7a8d'
);

const NewAdRoute = new Route('/postavljanje-oglasa');
const EditAdRoute = new Route('/izmena-oglasa/:adId');

const MyKPAdsRoute = new Route('/moj-kp/moji-oglasi');
const MyKPInvoiceRoute = new Route('/moj-kp/racuni');
const MyKPMessagesRoute = new Route('/moj-kp/poruke/inbox');
const MyKPMessageThreadRoute = new Route('/moj-kp/poruke');
const MyKPNotificationsRoute = new Route('/moj-kp/obavestenja');
const MyKPReviewsRoute = new Route('/moj-kp/ocene');

const PaymentRoute = new Route('/moj-kp/placanje');
const PaymentPopUpRoute = new Route('/moj-kp/popup-placanje');
const PaymentPrepaidPopUpRoute = new Route('/moj-kp/popup-prepaid-placanje');
const ExpiredAdListRoute = new Route('/moj-kp/oglasi-koji-isticu');
const MyKPFavoritesRoute = new Route('/moj-kp/pratim');
const MyKpSavedSearchesRoute = new Route('/moj-kp/sacuvane-pretrage');
const MyKPAddressBookRoute = new Route('/moj-kp/adresar');
const MyKPAccountRoute = new Route('/moj-kp/moj-nalog');
const MyKPAccountInfoBoxRoute = new Route(
  '/moj-kp/moj-nalog/informacije-u-oglasu'
);
const MyKPAccountPersonalDataRoute = new Route(
  '/moj-kp/moj-nalog/licni-podaci'
);
const MyKPAccountAddressRoute = new Route('/moj-kp/moj-nalog/moje-adrese');
const MyKPAccountChangeEmailRoute = new Route(
  '/moj-kp/moj-nalog/promena-e-maila'
);
const MyKPAccountChangePasswordRoute = new Route(
  '/moj-kp/moj-nalog/promena-lozinke'
);
const MyKPAccountCreatePasswordRoute = new Route(
  '/moj-kp/moj-nalog/kreiraj-lozinku'
);
const MyKPAccountEdInfoRoute = new Route(
  '/moj-kp/moj-nalog/ostala-podesavanja-naloga'
);
const MyKPAccountEmailNotificationRoute = new Route(
  '/moj-kp/moj-nalog/email-obavestenja'
);
const MyKPAccountNotificationAlertsRoute = new Route(
  '/moj-kp/moj-nalog/obavestenja-na-platformi'
);
const MyKPAccountCardsRoute = new Route(
  '/moj-kp/moj-nalog/moje-platne-kartice'
);
const MyKPAccountThemeRoute = new Route('/moj-kp/moj-nalog/promena-teme');

const MyKPAccountGdprRoute = new Route('/moj-kp/moj-nalog/moji-podaci');
const MyKPPromoRoute = new Route('/moj-kp/promocije/:id');
const MyKPEdRenewRoute = new Route('/moj-kp/obnova-oglasa/:id');
const MyKPRenewingRoute = new Route('/moj-kp/kp-obnavljac');
const MyKPKpIzlogRoute = new Route('/moj-kp/kp-izlog');
const MyKPPrepaidRoute = new Route('/moj-kp/prepaid');
const MyKPShippingRoute = new Route('/moj-kp/istorija-zakazivanja');
const PrepaidPaymentRoute = new Route('/moj-kp/prepaid/dopuna');
const SuccessfullyCreatedPrepaidInvoiceRoute = new Route(
  '/moj-kp/prepaid/dopuna/uspesno-kreiran-predracun'
);

const GoldAdRoute = new Route(
  'https://blog.kupujemprodajem.com/helpcentar/kp-prepaid-i-promocije/promocije/sta-je-zlatni-oglas-i-kako-da-ga-aktivirate/?utm_source=kp&utm_medium=interfejs&utm_campaign=zlatni_oglas'
);
const KPBlogRoute = new Route('https://blog.kupujemprodajem.com');
const KPHelpCenterKpLimiter = new Route(
  'https://blog.kupujemprodajem.com/helpcentar/moji-oglasi/postavljanje-oglasa/koliko-oglasa-mogu-besplatno-da-postavim/?utm_source=kp&utm_medium=popup&utm_campaign=ogranicavac'
);
const KPHelpCenterFeedbackRoute = new Route(
  'https://blog.kupujemprodajem.com/help-centar/?utm_source=kp&utm_medium=kontakt_forma&utm_campaign=help_centar'
);
const KPBlogJobRoute = new Route(
  'https://blog.kupujemprodajem.com/kako-da/kako-da-napravite-dobar-oglas-za-posao/?utm_source=kp&utm_medium=postavljanje_oglasa&utm_campaign=poslovi'
);
const KPBlogParkedRoute = new Route(
  'https://blog.kupujemprodajem.com/info/novo-na-kp-parkirani-oglas/'
);
const KPBlogRenewerRoute = new Route(
  'https://blog.kupujemprodajem.com/helpcentar/kp-prepaid-i-promocije/promocije/sta-je-kp-obnavljac-i-kako-se-aktivira/?utm_source=kp&utm_medium=interfejs&utm_campaign=obnavljac'
);
const KPBlogPsvRoute = new Route(
  'https://blog.kupujemprodajem.com/info/placena-standardna-vidljivost-oglasa/'
);
const KPBlogVbrRoute = new Route(
  'https://blog.kupujemprodajem.com/info/novo-na-kp-verifikovani-bankovni-racun/'
);
const KPBlogSafeTradeRoute = new Route(
  'https://blog.kupujemprodajem.com/kako-da/kako-da-bezbedno-kupujete-i-prodajete-preko-kp/?utm_source=kp&utm_medium=interfejs&utm_campaign=saveti_za_bezbednost'
);

const KPBlogBusinessHubRoute = new Route(
  'https://blog.kupujemprodajem.com/kp-za-vas-biznis/?utm_source=kp&utm_medium=interfejs&utm_campaign=kp_za_vas_biznis&utm_content=footer'
);

const KPBlogBusinessHubFormAnchorRoute = new Route(
  'https://blog.kupujemprodajem.com/kp-za-vas-biznis/?utm_source=kp&utm_medium=interfejs&utm_campaign=kp_za_vas_biznis&utm_content=footer#formular'
);

const KPBlogQuickEditPriceRoute = new Route(
  'https://blog.kupujemprodajem.com/helpcentar/moji-oglasi/upravljanje-oglasima/kako-da-brzo-menjate-cene-u-oglasima/?utm_source=kp&utm_medium=interfejs&utm_campaign=brza_izmena_cene'
);

const KPBlogCategoryPartnerRoute = new Route(
  'https://blog.kupujemprodajem.com/info/novo-na-kp-partner-grupe/?utm_source=kp&utm_medium=interfejs&utm_campaign=partner_grupe'
);

const KPBlogSalePartnerRoute = new Route(
  'https://blog.kupujemprodajem.com/info/kp-akcija/?utm_source=kp&utm_medium=interfejs&utm_campaign=kp_akcija'
);

const KPBlogFraudHelpRoute = new Route(
  'https://blog.kupujemprodajem.com/kako-da/kako-da-reagujete-ako-mislite-da-ste-prevareni-preko-kupujemprodajem/?utm_source=kp&utm_medium=interfejs&utm_campaign=prijava_prevare'
);

const AreYouHumanRoute = new Route('/provera');
const FraudRoute = new Route('/prijava');

const LoginRoute = new Route('/login');
const RegisterRoute = new Route('/registracija');
const ForgotPasswordRoute = new Route('/zaboravljena-lozinka');

const UserBlockedRoute = new Route('/blokiran-nalog');

const UserSoftBlockedRoute = new Route('/softblokiran-nalog');

const UserAcceptNewRulesRoute = new Route('/prihvatanje-pravila-uslovi');

const AccountActivationRoute = new Route('/aktivacija-naloga');

const TwoStepVerificationRoute = new Route('/verifikacioni-kod');

const JobReportUnsubscribeRoute = new Route(
  '/odjava-od-statistike-oglasa-za-poslove/:reportId',
  'job-unsubscribe-report'
);

const FacebookErrorRoute = new Route('/facebook-greska', 'facebook-error');

// Seller Rights Page
const LawOnObligations = new Route(
  'https://www.paragraf.rs/propisi/zakon_o_obligacionim_odnosima.html'
);
const TradeLaw = new Route(
  'https://www.paragraf.rs/propisi/zakon_o_trgovini.html'
);
const ElectronicCommerceAct = new Route(
  'https://www.paragraf.rs/propisi/zakon_o_elektronskoj_trgovini.html'
);
const ConsumerProtectionAct = new Route(
  'https://www.paragraf.rs/propisi/zakon_o_zastiti_potrosaca.html'
);
const EstablishmentDOO = new Route(
  'https://blog.kupujemprodajem.com/arhiva/kako-da-osnujete-d-o-o'
);
const EstablishmentOfEntrepreneurs = new Route(
  'https://blog.kupujemprodajem.com/arhiva/kako-da-se-registrujete-kao-preduzetnik'
);
const RegistrationApplication = new Route(
  'https://www.apr.gov.rs/upload/Portals/0/privredna%20drustva/2020/Osnivanje/JRPPS___Doo_102020.PDF'
);
const OnlineSalesVideo = new Route(
  'https://www.youtube.com/watch?v=BHVtBCzprR4'
);
const ValueAddedTaxVideo = new Route(
  'https://www.youtube.com/watch?v=hKb22aJLc-w'
);

const ColorRoute = new Route('/color');

const PosterRoute = new Route(`${API_URL}/oglasi.php`);

const PrepaidInfoRoute = new Route(
  'https://blog.kupujemprodajem.com/helpcentar/kp-prepaid-i-promocije/kp-prepaid-kredit/kako-da-uplatite-kp-prepaid-kredit-na-kupujemprodajem/'
);

const ForbiddenWordsBlogRoute = new Route(
  'https://blog.kupujemprodajem.com/category/helpcentar/nedozvoljeno-oglasavanje/'
);

const AppDownloadRoute = new Route(`${API_URL}/downloadApp.php`);

module.exports = {
  HomeRoute,
  HomeServiceRoute,
  HomeJobRoute,
  HomeShopWindowRoute,
  HomeFeedbackFormOpenRoute,
  CategoryShopWindowRoute,
  GroupRoute,
  CategoryRoute,
  CategoryServiceRoute,
  AdRoute,
  AboutUsRoute,
  CourierTermsAndConditionsRoute,
  TermsAndConditionsRoute,
  Terms20122022Route,
  Terms09062022Route,
  Terms05122016Route,
  Terms14032019Route,
  Terms05012022Route,
  Terms29092021Route,
  FairUsePolicyRoute,
  HelpAndContactRoute,
  AllUserReviewsRoute,
  SearchRoute,
  CategorySearchRoute,
  CategoryNewestRoute,
  GroupSearchRoute,
  PrivacyPolicyRoute,
  PrivacyPolicy19072018Route,
  PrivacyPolicy18052020Route,
  SellerRightsRoute,
  SmallBusinessRoute,
  KPLinkedInRoute,
  KPFacebookRoute,
  KPInstagramRoute,
  KPTikTokRoute,
  KPPlayStoreRoute,
  KPAppleStoreRoute,
  KPBookBearRoute,
  KPCareersRoute,
  KPBookRoute,
  AdPromoRoute,
  MediakitRoute,
  PriceListRoute,
  BannerInstructionRoute,
  PrepaidRoute,
  AdPromoSpecificRoute,
  PrepaidTermsRoute,
  NewestSearchRoute,
  SavedSearchRoute,
  SavedSearchNotificationRoute,
  AllUserAdsRoute,
  KpIzlogRoute,
  OtpLandingPageRoute,
  AreYouHumanRoute,
  NewAdRoute,
  EditAdRoute,
  MyKPAccountRoute,
  MyKPAccountInfoBoxRoute,
  MyKPAccountPersonalDataRoute,
  MyKPAccountAddressRoute,
  MyKPAccountChangeEmailRoute,
  MyKPAccountChangePasswordRoute,
  MyKPAccountCreatePasswordRoute,
  MyKPAccountEdInfoRoute,
  MyKPAccountGdprRoute,
  MyKPAccountEmailNotificationRoute,
  MyKPAccountNotificationAlertsRoute,
  MyKPAccountCardsRoute,
  MyKPAccountThemeRoute,
  MyKPInvoiceRoute,
  MyKPAddressBookRoute,
  MyKPAdsRoute,
  MyKPFavoritesRoute,
  MyKpSavedSearchesRoute,
  MyKPKpIzlogRoute,
  MyKPMessagesRoute,
  MyKPMessageThreadRoute,
  MyKPNotificationsRoute,
  MyKPPrepaidRoute,
  MyKPPromoRoute,
  MyKPEdRenewRoute,
  MyKPRenewingRoute,
  MyKPReviewsRoute,
  MyKPShippingRoute,
  GoldAdRoute,
  KPBlogRoute,
  KPHelpCenterKpLimiter,
  KPHelpCenterFeedbackRoute,
  KPBlogJobRoute,
  KPBlogParkedRoute,
  KPBlogRenewerRoute,
  KPBlogPsvRoute,
  KPBlogVbrRoute,
  KPBlogSafeTradeRoute,
  KPBlogBusinessHubRoute,
  KPBlogBusinessHubFormAnchorRoute,
  KPBlogQuickEditPriceRoute,
  KPBlogCategoryPartnerRoute,
  KPBlogSalePartnerRoute,
  KPBlogFraudHelpRoute,
  FraudRoute,
  LoginRoute,
  ForgotPasswordRoute,
  RegisterRoute,
  UserBlockedRoute,
  UserSoftBlockedRoute,
  UserAcceptNewRulesRoute,
  AccountActivationRoute,
  TwoStepVerificationRoute,
  JobReportUnsubscribeRoute,
  FacebookErrorRoute,
  PrepaidPaymentRoute,
  SuccessfullyCreatedPrepaidInvoiceRoute,
  PaymentRoute,
  PaymentPopUpRoute,
  PaymentPrepaidPopUpRoute,
  ExpiredAdListRoute,
  LawOnObligations,
  TradeLaw,
  ElectronicCommerceAct,
  ConsumerProtectionAct,
  EstablishmentDOO,
  EstablishmentOfEntrepreneurs,
  RegistrationApplication,
  OnlineSalesVideo,
  ValueAddedTaxVideo,
  ColorRoute,
  PosterRoute,
  PrepaidInfoRoute,
  AppDownloadRoute,
  ForbiddenWordsBlogRoute,
};
