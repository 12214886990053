import { ENVIRONMENT } from '@lib/constants/env.constants';

import { axiosInstance } from '@api/request/axiosInstance';

import { isProduction } from './isProduction';
import { LocalStorageAPI } from './localStorage';

const TTL = 7 * 60 * 1000; // 5 minutes

const getTimestamp = (url?: Location): string => {
  const timestamp = Date.now().toString();
  return url ? timestamp.substring(0, 9) : timestamp;
};

const shouldRefreshVersion = async (timestamp: string) => {
  const response = await axiosInstance.get(`/version.json?time=${timestamp}`);
  const { version } = response.data;
  return version !== process.env.NEXT_PUBLIC_SENTRY_RELEASE;
};

export const forceRefresh = async (url: Location) => {
  if (!isProduction || ENVIRONMENT === 'test') return;
  try {
    const isVersionChecked = LocalStorageAPI.getWithExpiry('isVersionChecked');
    if (isVersionChecked) return;

    const timestamp = getTimestamp(url);
    LocalStorageAPI.setWithExpiry('isVersionChecked', '1', TTL);

    const shouldRefresh = await shouldRefreshVersion(timestamp);

    if (shouldRefresh) {
      if (url) {
        window.location = url;
      } else {
        window.location.reload();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
