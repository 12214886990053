import type { ThunkAPI } from '@store/configureStore';
import { EmptyObject } from '@store/global/type';

import { apiRequest } from '../api/request/makeRequest';

import type {
  AdIdPayload,
  LogAdCreateStepPayload,
  LogAddressPayload,
  LogChangeThemePayload,
  LogDownloadAppPayload,
  LogEventPayload,
  LogFeedbackInterceptor,
  LogIsbnScanPayload,
  LogNiriGroupSuggestionsPayload,
  LogOrderCourierPayload,
  LogUpsellPromoPayload,
  LogWelcomeScreenResponse,
} from './type';

const LOGGING_URL = 'log';

const logWelcomeScreen = (thunkAPI: ThunkAPI) => (eventAction: string) =>
  apiRequest<LogWelcomeScreenResponse>(`${LOGGING_URL}/welcome-screen`, {
    method: 'POST',
    body: { eventAction },
    thunkAPI,
  });

const logNiriGroupSuggestions =
  (thunkAPI: ThunkAPI) => (data: LogNiriGroupSuggestionsPayload) =>
    apiRequest(`${LOGGING_URL}/niri-group-suggestions`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const logFmcgClick = (thunkAPI: ThunkAPI) => (data: EmptyObject) =>
  apiRequest(`${LOGGING_URL}/fmcg-click`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logAdCreateStep =
  (thunkAPI: ThunkAPI) => (data: LogAdCreateStepPayload) =>
    apiRequest(`${LOGGING_URL}/adcreate`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const logClickOnPhoneButton = (thunkAPI: ThunkAPI) => (data: AdIdPayload) =>
  apiRequest(`${LOGGING_URL}/click-phone-button`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logClickOnPromotionButton = (thunkAPI: ThunkAPI) => (data: AdIdPayload) =>
  apiRequest(`${LOGGING_URL}/click-promotion-button`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logFeedbackInterceptor =
  (thunkAPI: ThunkAPI) => (data: LogFeedbackInterceptor) =>
    apiRequest(`${LOGGING_URL}/feedback-interceptor`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const logAddress = (thunkAPI: ThunkAPI) => (data: LogAddressPayload) =>
  apiRequest(`${LOGGING_URL}/message-address`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logTrackingCode = (thunkAPI: ThunkAPI) => (data: EmptyObject) =>
  apiRequest(`${LOGGING_URL}/message-tracking-code`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logChangeTheme = (thunkAPI: ThunkAPI) => (data: LogChangeThemePayload) =>
  apiRequest(`${LOGGING_URL}/user-theme-change`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logUpsellPromo = (thunkAPI: ThunkAPI) => (data: LogUpsellPromoPayload) =>
  apiRequest(`${LOGGING_URL}/upsell-ad-promotions`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logDownloadApp = (thunkAPI: ThunkAPI) => (data: LogDownloadAppPayload) =>
  apiRequest(`${LOGGING_URL}/download-app-banner`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logOrderCourier =
  (thunkAPI: ThunkAPI) => (data: LogOrderCourierPayload) =>
    apiRequest(`${LOGGING_URL}/order-courier`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const logIsbnScan = (thunkAPI: ThunkAPI) => (data: LogIsbnScanPayload) =>
  apiRequest(`${LOGGING_URL}/isbn-scan`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const logEvent = (thunkAPI: ThunkAPI) => (data: LogEventPayload) =>
  apiRequest(`${LOGGING_URL}/log-event`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const LoggingAPI = {
  logWelcomeScreen,
  logNiriGroupSuggestions,
  logFmcgClick,
  logAdCreateStep,
  logClickOnPhoneButton,
  logClickOnPromotionButton,
  logFeedbackInterceptor,
  logAddress,
  logTrackingCode,
  logChangeTheme,
  logUpsellPromo,
  logDownloadApp,
  logIsbnScan,
  logOrderCourier,
  logEvent,
} as const;
