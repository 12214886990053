/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { AppInitialProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import nextCookie from 'next-cookies';
import { DefaultSeo } from 'next-seo';
import { MyAppContext, MyAppInitialProps, MyAppProps } from 'kp';

import { getDeviceInfo } from '@lib/app/getDeviceInfo';
import { setPlatformType } from '@lib/app/setPlatformType';
import isBotOrExtension from '@lib/isBotOrExtension';
import { isProduction } from '@lib/isProduction';
import runSSRMiddlewares from '@lib/ssrMiddlewares/runSSRMiddlewares';
import getHeaders from '@lib/ssrSetup/getHeaders';
import getNonce from '@lib/ssrSetup/getNonce';
import getUserAgent from '@lib/ssrSetup/getUserAgent';
import { GTM_ID } from 'src/lib/constants/env.constants';
import { initRouterListeners } from 'src/lib/initRouterEvents';

import { useDetectAdBlock } from '@hooks/banner/useDetectAdBlock';
import useGoogleAdManagerScript from '@hooks/banner/useGoogleAdManagerScript';
import { useSeoConfig } from '@hooks/seo/useSeoConfig';
import useRemoveHoverSafariMobile from '@hooks/useRemoveHoverSafariMobile';
import { useResetState } from '@hooks/useResetState';
import { useRouteChangeStart } from '@hooks/useRouteChangeStart';

import { setDevice, setLocals } from '@store/meta/slice';
import { selectModal } from '@store/modal/selector';
import { toggleModal } from '@store/modal/slice';
import { wrapper } from 'src/store/configureStore';

import { setNonce } from '@components/head/responsive/nonce';

import { emptyLayout } from '../main/layouts/responsive/Layout/Layout';

import '../main/styles/_dark-theme.scss';
import style from '../main/styles/_generic.reset.scss';

import '../main/styles/_settings.colors-basic.css';
import '../main/styles/_settings.colors-semantic.css';
import '../main/styles/nprogress.css';
import '../main/styles/react-datepicker.css';

const App = ({
  Component,
  pageProps = {},
  cookies,
  err,
  headers,
  nonce,
  isMobile,
}: MyAppProps) => {
  const router = useRouter();

  useRouteChangeStart();
  useResetState();
  useDetectAdBlock();
  useGoogleAdManagerScript();
  useRemoveHoverSafariMobile();

  const seoProps = useSeoConfig();

  useEffect(() => {
    setNonce(nonce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  import('src/lib/sentry').then((module) => {
    const { configureSentry } = module.sentry();
    configureSentry(router, cookies, headers);
  });

  const layout = isMobile ? Component.getLayoutMobile : Component.getLayout;
  const getLayout = layout || emptyLayout;

  return (
    <>
      <DefaultSeo {...seoProps} />
      {isProduction && (
        <>
          <Script id="GtagScript" nonce={nonce} strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                dummy: 0
              });
          `}
          </Script>
          <Script id="GtmScript" nonce={nonce} strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');`}
          </Script>
        </>
      )}
      <script
        id="GoogleOneTapScript"
        src="https://accounts.google.com/gsi/client"
        async
        defer
      />
      {getLayout(
        <Component
          {...pageProps}
          style={style}
          cookies={cookies}
          err={err}
          router={router}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

App.getInitialProps = wrapper.getInitialAppProps(
  (store) =>
    async ({
      Component,
      ctx,
    }: MyAppContext): Promise<MyAppInitialProps & AppInitialProps> => {
      const { req, res, asPath } = ctx;

      const cookies = nextCookie(ctx);

      if (req) {
        const isMiddlewareRedirect = await runSSRMiddlewares(ctx);
        if (isMiddlewareRedirect) {
          return {
            cookies,
            pageProps: {},
          };
        }
      }

      const nonce = getNonce(ctx);
      const userAgent = getUserAgent(ctx);
      const headers = getHeaders(ctx);
      const deviceInfo = getDeviceInfo(userAgent);
      store.dispatch(setDevice(deviceInfo));

      if (req) {
        await setPlatformType(cookies, deviceInfo, store);
      }

      const state = store.getState();
      const { channel, deviceType } = state.meta;

      if (req) {
        store.dispatch(
          setLocals({
            ...res.locals,
            cookies: req.headers.cookie,
            userAgent,
            asPath,
          })
        );

        if (res?.statusCode === 404 && isBotOrExtension(req)) {
          res.statusCode = 410;
        }
      }

      if (selectModal(state) !== null) {
        store.dispatch(toggleModal(null));
      }

      if (Component.getInitialProps) {
        await Component.getInitialProps(ctx);
      }

      return {
        cookies,
        headers,
        nonce,
        channel,
        isMobile: deviceType === 'mobile',
        pageProps: {},
      };
    }
);

initRouterListeners();

export default wrapper.withRedux(App);
