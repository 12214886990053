import { useEffect } from 'react';

import { GOOGLE_CLIENT_ID } from '@lib/constants/env.constants';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsUserLogged } from '@store/auth/selector';
import { loginViaOneTap } from '@store/auth/thunk';
import { EventActionAuth, EventTypeAuth } from '@store/auth/type';
import { AppDispatch } from '@store/configureStore';
import { RequestStatus } from '@store/global/type';
import { logEvent } from '@store/logging/thunk';

const initializeGoogleOneTap = (
  handleCredentialResponse: (
    response: google.accounts.id.CredentialResponse
  ) => Promise<void>,
  dispatch: AppDispatch
) => {
  if (window?.google?.accounts) {
    window.google.accounts?.id?.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
      cancel_on_tap_outside: false,
    });

    window.google.accounts?.id?.prompt(
      (notification: google.accounts.id.PromptMomentNotification) => {
        if (notification.isDisplayed()) {
          dispatch(
            logEvent({
              eventAction: EventActionAuth.showPrompt,
              eventType: EventTypeAuth.googleOneTap,
            })
          );
        }
      }
    );
  }
};

const GoogleOneTap = () => {
  const dispatch = useAppDispatch();
  const isUserLogged = useAppSelector(selectIsUserLogged);

  useEffect(() => {
    if (isUserLogged) {
      return;
    }

    const handleCredentialResponse = async (
      response: google.accounts.id.CredentialResponse
    ) => {
      if (response.credential) {
        const action = await dispatch(loginViaOneTap(response.credential));
        dispatch(
          logEvent({
            eventAction: EventActionAuth.clickLogin,
            eventType: EventTypeAuth.googleOneTap,
          })
        );
        if (action?.meta?.requestStatus === RequestStatus.fulfilled) {
          dispatch(
            logEvent({
              eventAction: EventActionAuth.successLogin,
              eventType: EventTypeAuth.googleOneTap,
            })
          );
        }
      }
    };

    initializeGoogleOneTap(handleCredentialResponse, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GoogleOneTap;
