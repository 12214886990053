import getConfig from 'next/config';

const {
  publicRuntimeConfig: {
    NODE_ENV,
    ENVIRONMENT,
    COOKIE_URL,
    ROOT_URL,
    API_URL,
    IMAGES_URL,
    DEVTOOLS_ENABLED,
    DARK_THEME_ENABLED,
    GA_TRACKING_ID,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID,
    FACEBOOK_APP_ID,
    GTM_ID,
    SENTRY_DSN,
    NEXT_ROOT_DIR,
    MOBILE_URL,
    CSP_REPORT_URL,
    CSP_REPORT_ENTROPY,
  },
} = getConfig();

const GOOGLE_CLIENT_ID =
  '57926949926-csf6111a08b0jeth4aie8lmrtdamoja7.apps.googleusercontent.com';

export {
  API_URL,
  COOKIE_URL,
  CSP_REPORT_ENTROPY,
  CSP_REPORT_URL,
  DARK_THEME_ENABLED,
  DEVTOOLS_ENABLED,
  ENVIRONMENT,
  FACEBOOK_APP_ID,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  GA_TRACKING_ID,
  GOOGLE_CLIENT_ID,
  GTM_ID,
  IMAGES_URL,
  MOBILE_URL,
  NEXT_ROOT_DIR,
  NODE_ENV,
  ROOT_URL,
  SENTRY_DSN,
};
